<template>
  <div class="record" :class="{ 'mobile-record': isMobile }">
    2018 - {{ nowYear }} PublicisGrowthOS.
    <img class="icpImg" src="@/assets/img/icpicon.png" alt="" />
    <a
      :style="isMobile ? 'margin-right: 12px' : 'margin-right: 16px'"
      href="https://beian.mps.gov.cn/#/query/webSearch?code=31010602008641"
      rel="noreferrer"
      target="_blank"
      >沪公网安备31010602008641号</a
    >
    <a href="https://beian.miit.gov.cn" target="_blank"> 沪ICP备2021007762号-1</a>
  </div>
</template>

<script>
export default {
  name: 'CopyrightFooter',
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      nowYear: new Date().getFullYear(),
    }
  },
}
</script>

<style lang="scss" scoped>
.record {
  color: #fff;
  position: fixed;
  font-size: 12px;
  bottom: 2.5%;
  right: 2%;
  display: flex;
  align-items: center;

  img {
    margin: 0 4px 0 6px;
  }

  a {
    display: flex;
    align-items: center;
  }

  a,
  a:link,
  a:visited,
  a:hover,
  a:active,
  a:focus {
    color: #fff;
  }
}

.mobile-record {
  bottom: 3%;
  left: 0;
  width: 100%;
  text-align: center;
  justify-content: center;
}

.icpImg {
  display: inline-block;
  width: 16px;
  height: 16px;
}
</style>
