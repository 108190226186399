/**
 * 常量配置
 */
export default {
  AESPUBLIC_KEY:
    'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDIufjLrteI62z7Daeb1ljYJn/2p+hDx/SKkj2Ni5PGbzB7KtVyiiXwIc4Cz53x4xjVIiQZGFXNwdHhWoqAtVjAbLuAsXqv4rMJoZmlblzvpzO/RZKSTWcyRt9cWISSneh15x6SExAypA04Bc2vJ2HOMejtyXJn1G9UxDbYuZntpwIDAQAB',
  // token的key值
  USER_TOKEN_KEY: 'Access-Token',
  // token在cookie中的key
  COOKIE_TOKEN: 'login_token',
  // 项目code，用于sso鉴权    ProductB/Data Center
  PROJECT_CODE: 'SSO',
  // 权限组key
  ACCESS_GROUP: 'SSAG',
  // response返回值中的状态码
  RESPONSE_STATUS: {
    // 成功，正常
    SUCCESS: 0,
    // 文件模版错误
    FILE_TEMPLATE_ERROR: 1,
    // 用户未登录
    NEED_LOGIN: 100000,
    // 用户不存在
    USER_EXIST: 100011,
    // 不合法参数
    ILLEGAL_ARGUMENT: 100012,
    // 无访问权限
    NOT_AUTH: 100013,
    // 用户不存在

    // 其它错误
    OTHER: 999999,
    // 管理员状态
    SUPUSER: 1,
    ACCOUNTSTATUS: 0,
  },
  // QUESTIONNAIRE_URL:'https://wj.qq.com/s2/9587624/668a/',
  QUESTIONNAIRE_URL: 'https://wj.qq.com/s2/10624525/bdbd/',
  SSO_HANDBOOK: 'https://www.notion.so/SSO-User-Handbook-f6f4d85fe3204ecab37dc36b222caf68',
  MRCL_URL: 'https://marcel.ai/classes',
}
