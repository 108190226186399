export default {
  'dc:请输入': 'Please input',
  'dc:请输入名称': 'Please input name',
  'dc:请选择': 'Please select',
  'dc:GPT请输入': 'Please enter...',
  'dc:新增': 'Add',
  'dc:退出': 'Logout',
  'dc:刷新': 'Refresh',
  'dc:删除': 'Delete',
  'dc:移除': 'Remove',
  'dc:计算': 'Calculating',
  'dc:详情': 'Detail',
  'dc:下载': 'Download',
  'dc:上传': 'Upload',
  'dc:确认': 'Confirm',
  'dc:取消': 'Cancel',
  'dc:保存成功': 'Save success.',
  'dc:上传成功': 'Upload success.',
  'dc:成功': 'Success',
  'dc:登录': 'Sign Up',
  'dc:保存': 'Save',
  'dc:加载中': 'Loading',
  'dc:运行中': 'Runing',
  'dc:返回': 'Back',
  'dc:删除成功': 'Delete success.',
  'dc:查看': 'View',
  'dc:操作': 'Action',
  'dc:编辑': 'Edit',
  'dc:发送': 'Send',
  'dc:是': 'Yes',
  'dc:否': 'No',
  'dc:发送邮件': 'Send Email',
  'dc:邮件通知': 'Email Notification',
  'dc:同意': 'Agree',
  'dc:不同意': 'Disagree',
  'dc:关闭': 'Close',
  'dc:生成': 'Generate',
  'dc:重置': 'Reset',
  'dc:提交': 'Submit',
  'dc:未分配': 'Unallocated',
  'dc:已分配': 'Allocated',
  'dc:转变': 'Switch',
  'dc:数据为空': 'Data is empty',
  'dc:复制': 'Copy',
  'dc:欢迎': 'Welcome',
  // Header
  'dc:用户反馈': 'User Feedback',
  'dc:类别': 'Category',
  'dc:内容': 'Content',
  // 修改密码
  'dc:修改密码': 'Change Password',
  'dc:用户名字': 'Account Name',
  'dc:新密码': 'New Password',
  'dc:确认新密码': 'Confirm the New Password',
  'dc:请输入拒绝理由': 'Please input reject reason',
  'dc:提醒': 'Reminder',
  // 用户维护
  'dc:名字': 'Name',
  'dc:密码': 'Password',
  'dc:禁止': 'Forbid',
  'dc:恢复': 'Recover',
  'dc:拒绝 ': 'Reject',
  'dc:申请': 'Apply',
  'dc:管理': 'Manage',

  'dc:选择版本': 'Select Version',
  // "dc:项目名称":"Product Name",
  'dc:产品名称': 'Product Name',
  'dc:用户组管理': 'Group Management',
  'dc:集团信息': 'Group Information',
  'dc:用户组信息': 'Group Information',
  'dc:请输入账号名称': 'Please input account name',
  'dc:请输入产品名称': 'Please input product name',
  'dcH:用户组管理': 'Group Manager',
  'dc:密码已重置，请重新登录': 'The password has been reset,please login again.',

  'dc:能否请您花几分钟宝贵的时间,单击以下链接给SSO系统一些反馈？':
    'Could you please spend a few minutes of your precious time on giving some feedback of the SSO system by clicking the link below?',
  'dc:请您花几分钟宝贵的时间通过单击下面的链接提供一些有关':
    'Could you please spend a few minutes of your precious time on giving some feedback of the ',
  'dc:的反馈？': ' by clicking the link below?',

  'dc:您确定要去用户组吗?': 'Do you want to go to user group?',
  'dc:您确定要添加该用户吗?': 'Do you confirm to add this user?',
  'dc:您确定要拒绝此用户吗?': 'Do you confirm to reject this user?',
  'dc:您确定要删除此用户吗?': 'Do you confirm to delete this user?',
  'dc:您确认要邀请此用户吗?': 'Do you confirm to invite ths user?',
  'dc:请选择或者输入': 'Please select or input a user',
  'dc:没有结果。请邀请您要找的人注册。':
    'No result. Please invite the people you looking for to register.',
  'dc:请输入账号名称或邮箱': 'Please input account name or email address',

  'dc:您确认要禁止该用户吗?': 'Do you confirm to forbid this account?',
  'dc:您确认要恢复该用户吗?': 'Do you confirm to recover this account?',
  'dc:您确认要拒绝该用户吗?': 'Do you confirm to reject this account?',
  'dc:您确认要删除该用户吗?': 'Do you confirm to delete this account?',
  'dc:您是否确认添加此帐户?': 'Do you confirm to add this account?',
  'dc:您是否确认添加此Lion ID?': 'Do you confirm to add this Lion ID?',

  'dc:您确认要禁止该组吗?': 'Do you confirm to forbid this group?',
  'dc:您确认要恢复该组吗?': 'Do you confirm to recover this group?',
  'dc:您确认要拒绝该组吗?': 'Do you confirm to reject this group?',
  'dc:您确认要删除该组吗?': 'Do you confirm to delete this group?',
  'dc:您确认退出该组吗?': 'Do you confirm to quit this group?',
  'dc:您是否确认申请改组吗?': 'Do you confirm to apply this group?',
  'dc:您确定要进入用户管理吗?': 'Do you want to go to user management?',
  'dc:您是否确认要申请该用户组？您的申请将由该组管理员审批，审批结果将会邮件告知您。':
    'Do you confirm to apply this group? Your application would be approved by the group admin, and the result will sent to you via e-mail.',

  // 账户角色分配
  'dc:新增账户': 'Add Account',
  'dc:添加LION ID': 'Add LION ID',
  'dc:代理商': 'Agency',
  'dc:权限申请列表': 'Access Application List',
  'dc:更新时间': 'Update Time',
  'dc:用户手册': 'User Handbook',
  'dc:你确定选择这个媒介吗?': 'Do you confirm to choose this agency?',

  'dc:请输入关键词': 'Please input keyword',
  'dc:账户角色分配': 'Account_Role Allocation',
  'dc:产品码': 'Product Code',
  'dc:产品地址': 'Product URL',
  'dc:介绍': 'Introduction',
  'dc:问卷调查': 'Questionnaire',
  'dc:马上填写': 'Fill Now',
  'dc:稍后填写': 'Later',
  'dc:您确认注册这个产品吗?': 'Do you confirm to register this product?',
  'dc:您确认修改该产品吗?': 'Do you confirm to edit this product?',
  'dc:您确认选择该产品吗?': 'Do you confirm to select this product?',
  'dc:您确认禁止该产品吗?': 'Do you confirm to forbid this product?',
  'dc:您确认恢复该产品吗?': 'Do you confirm to recover this product?',
  'dc:您确认删除此访问权限吗?': 'Do you confirm to delete this access?',
  'dc:您确认添加此访问权限吗?': 'Do you confirm to add this access?',
  'dc:您确认编辑此访问权限吗?': 'Do you confirm to edit this access?',
  // 角色
  'dc:角色分配': 'Role Allocation',
  'dc:角色名': 'Role Name',
  'dc:帐户类型': 'Account Type',
  'dc:代理商/品牌': 'Agency/Brand',
  'dc:成员名字': 'Member Name',
  'dc:您确认分配这个角色吗?': 'Do you confirm to allocate this role?',
  'dc:您确认删除这个角色吗?': 'Do you confirm to delete this role?',

  'dc:提交申请': 'Submit',
  'dc:人工审核': 'Audit',
  'dc:邮件确认': 'E-mail',
  'dc:申请完成': 'Finish',
  'dc:待处理': 'Pending',
  'dc:审批通过': 'Approve',
  'dc:拒绝': 'Refusedd',
  'dc:首页': 'Home',
  'dc:用户中心': 'User Center',
  // 权限
  'dc:产品名称': 'Product Name',
  'dc:新增产品': 'Add Product',
  'dc:产品列表': 'Product List',
  'dc:选择产品': 'Select product',
  'dc:权限名称': 'Access Name',
  'dc:新增权限': 'Add Access',
  'dc:权限': 'Access',
  'dc:权限列表': 'Access List',
  'dc:客户': 'Client',
  'dc:模块': 'Module',
  'dc:模块可视化': 'Module Visualization',
  'dc:模块名称': 'Module Name',
  'dc:权限code': 'Access Code',
  'dc:添加权限': 'Add Access',
  'dc:规则命名': 'Role Name',
  'dc:描述': 'Description',
  'dc:代理商': 'Agency',
  'dc:产品注册': 'Product Register',
  'dc:添加角色': 'Add Role',
  'dc:编辑角色': 'Edit Role',
  'dc:角色列表': 'Role List',
  'dc:编辑权限': 'Edit Access',
  'dc:权限分配': 'Access Allocation',
  'dc:权限详情': 'Detail',
  'dc:状态': 'Status',
  'dc:分配': 'Allocate',
  'dc:未选择': 'No Selected',
  'dc:已选择': 'Selected',
  'dc:跳转至权限列表': 'Turn to access list',
  'dc:部门': 'Division',
  'dc:指定': 'Assign',
  // 账号
  'dc:账号名称': 'Account Name',
  'dc:账号管理': 'Account Management',
  'dc:账号维护': 'Account Maintaining',
  'dc:账号关键词': 'Input Keywords',
  'dc:账号状态': 'Account Status',
  'dc:账号列表': 'Account List',
  'dc:邀请人的邮箱': "Inviter's mailbox",
  'dc:新增账号': 'Add Account',
  'dc:请选择产品': 'Please select product',
  'dc:集团信息': 'Group Information',

  'dc:新增模块': 'Add Module',
  'dc:编辑模块': 'Edit Module',
  'dc:删除模块': 'Delete Module',
  'dc:您是否确认添加此模块?': 'Do you confirm to add this module?',
  'dc:模块名称_1st': 'Module Name_1st',
  'dc:模块名称_2st': 'Module Name_2nd',
  // 组列表
  'dc:分配到组': 'Assign To Group',
  'dc:用户组': 'Group',
  'dc:新增用户组': 'Add Group',
  'dc:编辑用户组': 'Edit Group',
  'dc:添加用户组': 'Add User Group',
  'dc:用户组名称': 'Group Name',
  'dc:用户组列表': 'Group List',
  'dc:选择管理员': 'Select Admin',
  'dc:选择成员': 'Select Member',
  'dc:请输入用户组名称': 'Please input group name',
  'dc:请输入并选择': 'Please input and select',
  'dc:是否确认将这些访问权限分配给此组?': 'Do you confirm to allocate these access to this group?',
  'dc:你确认当前分配吗？': 'Confirm current assignment?',

  'dc:您确认邀请吗?': 'Do you confirm the invitation?',
  'dc:请输入想要邀请的同事邮箱': 'Please input the e-mail address',
  'dc:注意每次最多同时邀请5位同事': 'Maximum 5 e-mails at a time',
  'dc:没有结果？请邀请您要找的人注册':
    'No result? Please invite the person you looking for to register',
  'dc:请输入用户名称或邮箱': 'Input User Name/E-mail',

  'dc:您确认将这些访问权限分配给该组吗？':
    'Do you confirm that the current dat accesses are assigned to this group?',
  'dc:您确认分配这个组吗？':
    'Do you confirm that the current operation accesses are assigned to this group?',
  'dc:您是否确认切换到数据访问页面并保存当前更改?':
    'Do you confirm to switch to the data access page and save the current changes?',

  'dc:确认将当前操作访问权限分配给该组吗':
    'Confirm assigning the current operation access to this group?',
  'dc:确认将当前数据访问权限分配给该组吗':
    'Confirm assigning the current data access to this group?',

  'dc:确认切换到数据访问页面并保存当前更改':
    'Confirm switching to the data access page and saving current changes?',
  'dc:确认切换到操作访问页面并保存当前更改':
    'Confirm switching to the operation access page and saving current changes?',

  'dc:确认切换到客户端并保存当前更改吗':
    'Confirm switching to “Client” and saving current changes?',
  'dc:确认切换到行业并保存当前更改吗':
    'Confirm switching to “Industry” and saving current changes?',

  'dc:角色管理': 'Role Management',
  'dc:管理员': 'Admin',
  'dc:修改代理': 'Change Agency',
  // 用户组
  'dc:新增用户': 'Add User',
  'dc:用户名称': 'User Name',
  'dc:创建时间': 'Create Time',
  'dc:用户组人数': 'Number of Members',
  'dc:组管理员列表': 'Group Manager/Group List',
  'dc:用户组管理': 'Group Management',
  'dc:组名/组管理者': 'Input Group Name/Group Admin',
  'dc:组管理人员列表': 'Group Management/User List',
  'dc:用户列表': 'User List',
  'dc:操作权限': 'OPERATION ACCESS',
  'dc:数据权限': 'DATA ACCESS',
  'dc:编辑账号': 'Edit Account',
  'dc:用户组管理员': 'Group Manager',
  'dc:重设密码': 'Reset Password',
  'dc:翻译': 'Translation',
  'dc:公告列表': 'Announcement List',
  'dc:新增发布': 'Add Announcement',
  'dc:版本': 'Version',
  'dc:发布时间': 'Launch Time',
  'dc:发布者': 'Author',
  'dc:新增公告信息': 'Add Announcement',
  'dc:发布版本': 'Launch Version',
  'dc:发布内容': 'Launch Content',
  'dc:选择日期': 'Please select date',

  // 用户管理
  'dc:请输入用户名称': 'Input User Name',
  'dc:结果如下。请选择您需要的会员并确认':
    'The result as below. Please select the member you need and confirm',
  'dc:没有结果。请邀请您要找的人注册。':
    'No result.Please invite user or create account to complete registration.',
  'dc:邀请': 'Invite',
  'dc:输入邮箱地址': 'Input e-mail address',
  'dc:请输入正确的邮箱': 'Please input your vaild email',
  'dc:您确认要添加此组吗?': 'Do you confirm to add this group?',
  'dc:您确认要编辑此组吗?': 'Do you confirm to edit this group?',
  'dc:请输入用户组名称/用户组管理员': 'Please input group name/admin',
  'dc:您确定要修改此组成员吗？': 'Do you confirm to edit this group member?',
  'dc:禁用': 'Forbid',
  'dc:恢复': 'Restore',
  'dc:驳回': 'Reject',
  'dc:品牌': 'Brand',
  'dc:邮箱': 'E-mail',
  'dc:添加品牌': 'Register',
  'dc:查找': 'Search',
  'dc:修改品牌': 'Change Brand',
  'dc:品牌名称': 'Brand Name',
  'dc:你确认改变你的品牌？': 'Do you confirm to change your brand?',
  'dc:审批驳回': 'Refused',
  'dc:权限管理': 'Operation Access',
  'dc:品牌定义': 'Product Definition',
  'dc:权限定义': 'Access Definition',
  'dc:角色定义': 'Role Definition',
  'dc:编码': 'Code',
  'dc:URL': 'URL',
  'dc:产品': 'Product',
  'dc:您确定要删除吗?': 'Are you Sure you want to delete?',
  'dc:重载': 'Reload',
  'dc:时间范围': 'Time Range',
  'dc:开始日期': 'Start Date',
  'dc:结束日期': 'End Date',
  'dc:重新加载': 'Reload',
  'dc:修改密码': 'Change Password',
  'dc:新密码与确认密码不匹配': 'The new password and password to confirm do not match.',
  'dc:密码不少于8位，不大于20位':
    'The password is no less than 8 characters and no more than 20 characters.',
  'dc:仅支持数字、大小写字母': 'Only supports number, capital and lowercase letter.',
  'dc:修改密码成功，请重新登录': 'Change the password successfully, please log in again.',
  'dc:请输入密码': 'Please input the password',
  'dc:您确定要修改密码吗？': 'Do you confirm to change this password?',

  'dc:模块列表': 'Module List',
  'dc:您是否确认删除此模块?': 'Do you confirm to delete this module？',
  'dc:您是否确认编辑此模块?': 'Do you confirm to edit this module？',
  'dc:请按规则输入': 'Please input valid format',
  'dc:历史记录': 'Historical Record',
  'dc:所有记录': 'All Records',
  'dc:审批人': 'Approver',
  'dc:纬度': 'Dimension',
  'dc:行业': 'Industry',
  'dc:用于访问应用程序和使用顾问。请联系':
    'For access application & usage consultant. please contact Joseph Hu',
  'dc:寻求帮助': 'for help.',
  'dc:之前需要通过电子邮件批准您的直线经理的访问权限':
    'Email approval about access permission from your line manager is required previously.',
  'dc:去首页': 'Go to Homepage',
  'dc:请添加数据': 'Please add data',
  // 反馈中心
  'dc:反馈中心': 'Feedback Center',
  'dc:调查列表': 'Survey List',
  'dc:反馈列表': 'Feedback List',
  'dc:调查名称': 'Survey Name',
  'dc:提交时间': 'Submit Time',
  'dc:新增调查信息': 'Add Survey',
  'dc:反馈类别': 'Feedback Category',
  'dc:发布公告': 'Launch Announcement',
  'dc:单击下面的链接以创建新调查': 'Click the link below to create a new survey:',
  'dc:或者您可以去其他平台创建调查': 'Or you can go to other platform to create survey.',
  'dc:反馈详情': 'Feedback Details',
  'dc:解决': 'Solve',
  'dc:未解决': 'Unsolve',
  'dc:编辑公告信息': 'Edit Announcement',
  'dc:跳转至公告列表': 'Turn to announcement list',
  'dc:上一页': 'Previous Page',
  'dc:下一页': 'Next Page',
  'dc:可见': 'Visible',
  'dc:不可见': 'Invisible',
  'dc:确定删除?': 'Confirm deletion?',
  'dc:编辑调查信息': 'Edit Survey',
  'dc:公告信息详情': 'View Announcement',
  'dc:开': 'Open',
  'dc:关': 'Close',
  'dc:确认添加': 'Confirm addition?',
  'dc:确认修改': 'Confirm edition?',
  'dc:确认提交': 'Confirm submittion?',
  'dc:公告': 'Announcement',
  'dc:仅支持编号。大写和小写字母': 'Only support number. capital and lower-case letter;',
  'dc:仅支持100个字符': 'Only support 100 characters;',

  // 改版首页数据
  'dc:正确使用': 'MAKE IT RIGHT',
  'dc:使其魔幻': 'MAKE IT MAGIC',
  'dc:使其工作': 'MAKE IT WORK',
  'dc:常用的': 'My Favourite',
  'dc:团队APP': 'Team App',
  'dc:知识技能': 'KNOWLEDGE & SKILL CENTER',
  'dc:资源': 'RESOURXES',
  'dc:运动': 'CAMPAIGN',
  // 产品详情
  'dc:什么是IT?': 'WHAT IS IT?',
  'dc:何时何地使用?': 'PROBLEMS WE ARE SOLVING?',
  'dc:世界卫生组织应该使用它?': 'WHY USE IT?',
  'dc:如何访问?': 'HOW TO GET ACCESS?',
  'dc:演示': 'DEMO',
  'dc:文件夹': 'FILES',
  'dc:转到主页': 'GO TO HOMEPAGE',
  'dc:获取访问权限': 'GET ACCESS',

  'dc:第三方工具': '3rd Party Tools',
  'dc:简介': 'INTRODUCTION',
  'dc:什么是IT': 'WHAT IS IT',
  'dc:为什么要使用它': 'WHY USE IT',
  'dc:申请权限的方式': 'HOW TO GET ACCESS',
  'dc:附件': 'FILE',
  'dc:produc描述': 'DESCRIPTION',
  'dc:用途': 'USAGE',
  'dc:特点和优势': 'FEATURES & BENEFITS',
  'dc:智能机器人': 'ChatGPT',
  'dc:确认删除': 'Confirm  Deletion ?',
  'dc:咨询费共花费': 'Total Spending',
  'dc:新增会话': 'New Chat',
  'dc:你好今天我能为您提供什么帮助？': 'Hello! How can I assist you today?',
  'dc:请认真阅读使用规范': 'Please read the usage specification carefully',
  'dc:责任声明1':
    "Disclaimer: This is a test version using ChatGPT (gpt-3.5-turbo) language model. It's important to note that the system may occasionally generate incorrect or misleading information, as well as produce offensive or biased content. It is not intended to give advice. Therefore, please verify the output before using it.",
  'dc:责任声明2':
    'Kindly comply with the relevant policies and requirements of the Groupe regarding data security and privacy protection obligations. All chat history will be recorded and analyzed for future improvement and compliance monitoring.',
  'dc:责任声明3':
    'Please do not disclose any business data or confidential information associated with our Groupe or our clients. Any confidential information shared that violates Groupe or client protocol will result in strong and immediate action.',
  'dc:责任声明4':
    'Furthermore, avoid disclosing any personal information related to company employees or clients, and exercise caution in preventing data privacy violations. It is also recommended not to upload personal information with cell phone or device ID information.',
  'dc:责任声明5':
    'Prior to utilizing any generated content, please ensure it is compliant with advertising law and free from any intellectual property infringement.',
  'dc:责任声明6':
    'Due to the limitation of the language model, conversations are delivered to the AI engine with a historical conversation limit of 2000 words, which could result in exponential costs. Therefore, please start a new chat if a new context is applicable.',
  'dc:责任声明7':
    'All chat history will be recorded and tracked at the Groupe level for future improvement and compliance monitoring. Strict action will be taken if any of the above protocols are violated.',
  'dc:换行': 'New Line',
  'dc:剩余流量': 'Remaining Quota',
  'dc:你的流量用完了': 'Your dosage has run out.',
  'dc:您的对话已超出限制': 'Your conversation has exceeded the limit.',
  'dc:EDGEAI': 'EDGE AI',
  'dc:帮助中心': 'Help',
  'dc:IRIS映射列表': 'IRIS Mapping List',
  'dc:请选择审核状态': 'Please select Audit Status',
  'dc:级别2': 'Level2',
  'dc:级别3': 'Level3',
  'dc:批准人': 'Approver',
  'dc:审核状态': 'Audit Status',
  'dc:正常': 'Normal',
  'dc:例如:MurielLiu': 'Sample:MurielLiu',
  'dc:选择模块': 'Select module',
  'dc:已发送重置邮件给到管理员，请等待审批邮件通知！':
    'A reset email has been sent to the administrator, please wait for the approval email notification!',
  'dc:请输入Code关键词': 'Please enter the Code keyword',
  'dc:名称': 'Title',
  'dc:分配给客户': 'Assign to Client',
  'dc:产品类型': 'Product Type',
  'dc:归属': 'Owner',
  'dc:功能和优点': 'Features & Benefits',
  'dc:核心特征': 'Core Features',
  'dc:关键利益': 'Key Benefits',
  'dc:使用': 'Usage',
  'dc:示例': 'Example',
  'dc:编辑产品': 'Edit Product',
  'dc:所有输入框都需要填写，否则无法提交。':
    'All input boxes need to be filled in, otherwise it can not be submitted.',
  'dc:单击此链接查看示例。': 'Click on this link to see an example.',
  'dc:所有输入框都需要填写.': 'All input boxes need to be filled in.',
  'dc:有效期': 'Validity',
  'dc:有效开始日期': 'Validity start time',
  'dc:有效结束日期': 'Validity end time',
  'dc:通过电子邮件邀请': 'Invite via e-mail',
  'dc:创建帐户': 'Create Account',
  'dc:可见性': 'Visibility',
  'dc:全部可见': 'All Visible',
  'dc:部分可见': 'Partially Visible',
  'dc:最多5个电子邮件地址': 'Up to 5 e-mail addresses',
  'dc:至少选择一个选项': 'Choose at least one option',
}
