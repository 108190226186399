<template>
  <el-dialog
    :visible.sync="show"
    width="480px"
    :top="showType == 1 ? '8%' : '12%'"
    :close-on-click-modal="false"
    class="addUser-dialog home"
    :class="{ 'groupManager-detail-dialog': showType == 1 }"
    :title="$t('dc:新增用户')"
  >
    <el-input
      class="input-black"
      v-if="showType == 0"
      v-model="searchParams.context"
      :placeholder="$t('dc:请输入账号名称或邮箱')"
      @keyup.enter.native="search"
    ></el-input>
    <div class="searchMsg tabe-header-black" v-if="showType == 1">
      <el-alert
        :title="$t('dc:结果如下。请选择您需要的会员并确认')"
        type="success"
        class="green"
        :closable="false"
      >
      </el-alert>
      <div class="poc-table">
        <el-table
          ref="multipleTable"
          v-loading="loading"
          :data="tableData"
          class="userTable"
          style="height: 410px !important; min-height: 410px !important"
          @select="select"
          @row-click="rowClick"
          @selection-change="selectionChange"
        >
          <el-table-column type="selection" width="46" align="center" :selectable="selectInit">
          </el-table-column>
          <el-table-column
            v-for="item in columnOptions"
            :key="item.key"
            :prop="item.chlidren ? null : item.key"
            :label="item.name"
            :fixed="item.fixed"
            :sorter="item.sortable"
            :width="item.width"
            show-overflow-tooltip
          >
            <!-- <el-table-column
              v-for="chlid in item.chlidren"
              v-if="chlid.visible"
              :key="chlid.key"
              :prop="chlid.key"
              :label="chlid.name"
              :fixed="chlid.fixed"
              :sorter="chlid.sortable"
              :min-width="item.width"
              show-overflow-tooltip
            ></el-table-column> -->
          </el-table-column>
        </el-table>
        <!-- <el-pagination
        class="poc-table"
        @current-change="handleCurrentChange"
        layout="total, prev, pager, next"
        :pager-count="pagerCount"
        :total="searchParams.total">
      </el-pagination> -->
        <div class="poct-pagination">
          <el-pagination
            @size-change="pageSizeChange"
            @current-change="handleCurrentChange"
            popper-class="popper-black"
            :pager-count="pagerCount"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="10"
            layout="total, prev, pager, next"
            :total="searchParams.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="searchMsg" v-if="showType == 2">
      <el-alert
        :title="$t('dc:没有结果。请邀请您要找的人注册。')"
        type="error"
        class="red"
        :closable="false"
        show-icon
      >
      </el-alert>
      <div class="email4account-group" v-if="!showEmailStatus">
        <a @click="showEmail">{{ $t('dc:通过电子邮件邀请') }}</a>
        <a @click="showAccount">{{ $t('dc:创建帐户') }}</a>
      </div>
      <el-input
        v-if="showEmailStatus"
        class="input-black"
        v-model="addEmail"
        :placeholder="$t('dc:输入邮箱地址')"
      ></el-input>
    </div>
    <div slot="footer">
      <el-button
        class="marginR20 btn-black"
        v-if="showEmailStatus && (showType == 1 || showType == 2)"
        @click="show = false"
        >{{ $t('dc:取消') }}</el-button
      >
      <el-button
        class="marginR20 btn-black"
        v-if="(!showEmailStatus && showType == 0) || showType == 1"
        @click="show = false"
        >{{ $t('dc:取消') }}</el-button
      >
      <el-button class="btn-green" v-if="showType == 0" type="primary" @click="search">{{
        $t('dc:查找')
      }}</el-button>
      <el-button
        class="btn-black"
        v-if="showType == 1 || (showType == 2 && showEmailStatus)"
        @click="backSearch"
        >{{ $t('dc:返回') }}</el-button
      >
      <el-button
        class="btn-green"
        v-if="showType == 1 && !showEmailStatus"
        type="primary"
        @click="submit"
        >{{ $t('dc:确认') }}</el-button
      >
      <el-button
        class="btn-green"
        v-if="showType == 2 && showEmailStatus"
        type="primary"
        @click="submit"
        >{{ $t('dc:邀请') }}</el-button
      >
    </div>
    <el-dialog
      class="groupManager-detail-dialog home"
      width="30%"
      top="10%"
      :title="title"
      :visible.sync="innerVisible"
      append-to-body
    >
      <div style="padding: 10px 16px 10px">{{ context }}</div>
      <div slot="footer" class="dialog-footer">
        <el-button class="btn-black" @click="innerVisible = false">{{ $t('dc:取消') }}</el-button>
        <el-button class="btn-green" type="primary" @click="confrom">{{ $t('dc:确认') }}</el-button>
      </div>
    </el-dialog>
    <addAccountDialog
      v-if="show4AddDialog"
      :modal="false"
      :dialogTop="'8%'"
      :managerGroupId="managerGroupId"
      :modal-append-to-body="true"
      :visible.sync="show4AddDialog"
      :title="addAccountTitle"
      :popperClass="'homeSelect'"
      @queryApi="queryApi"
    ></addAccountDialog>
  </el-dialog>
</template>
<script>
import { mapActions } from 'vuex'
import UsuallyDialog from '@/views/dialog/usuallyDialog'
import addAccountDialog from '@/views/dialog/addAccountDialog'
export default {
  components: {
    UsuallyDialog,
    addAccountDialog,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    managerGroupId: Number,
  },
  watch: {
    show(v) {
      this.$emit('update:visible', v)
    },
    visible(v) {
      this.show = v
    },
    addEmail(v) {},
  },
  data() {
    return {
      show: this.visible,
      innerVisible: false,
      show4AddDialog: false,
      addAccountTitle: this.$t('dc:新增账号'),
      title: '',
      context: '',
      addEmail: '',
      showType: 0,
      pagerCount: 5,
      searchParams: {
        groupId: this.managerGroupId,
        context: '',
        pageNum: 1,
        total: 10,
        pageSize: 10,
      },
      loading: false,
      inviteParams: {
        username: null,
        email: '',
        groupId: this.managerGroupId,
      },
      allowSendEmail: false,
      tableData: [],
      multipleSelection: [],
      showEmailStatus: false,
    }
  },
  computed: {
    columnConfig() {
      return [
        {
          key: 'username',
          name: this.$t('dc:用户名称'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false,
          width: 180,
        },
        {
          key: 'email',
          name: this.$t('dc:邮箱'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: null,
        },
      ]
    },
    columnOptions() {
      return this.columnConfig.filter((n) => n.visible)
    },
  },
  mounted() {
    this.initData()
  },
  methods: {
    ...mapActions(['updateUserInfo', 'updataAgencyList', 'updataClientList']),
    initData() {
      this.getUserAgencyList()
      this.getUserClientList()
    },
    // 获取产品列表
    async getUserAgencyList() {
      let { status, data } = await $Api.common.getAllAgencyList()
      if (status === this.$RESPONSE_STATUS.SUCCESS) this.updataAgencyList(data)
    },
    // 获取产品列表
    async getUserClientList() {
      let { status, data } = await $Api.common.getAllClientList()
      if (status === this.$RESPONSE_STATUS.SUCCESS) this.updataClientList(data)
    },
    confrom() {
      this.submitAddUser()
    },
    // 发送邀请信息
    async submitAddUser() {
      if (this.multipleSelection.length > 0) {
        this.inviteParams.username = this.multipleSelection[0].username
        this.inviteParams.email = this.multipleSelection[0].email
      }
      if (this.showType == 2 && this.addEmail !== '' && this.showType) this.sendEmail()
      else {
        let { status, data } = await $Api.groupAdmin.addUser(this.inviteParams)
        if (status === this.$RESPONSE_STATUS.SUCCESS) {
          this.innerVisible = false
          this.show = false
          this.$emit('close', true)
        } else {
          this.innerVisible = false
        }
      }
    },
    async sendEmail() {
      let params = {
        type: this.showType,
        emailList: [{ email: this.addEmail }],
        groupId: this.managerGroupId,
      }
      let { status, data } = await $Api.group.getInvitationMail(params)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.innerVisible = false
        this.show = false
        this.$emit('close', true)
      } else this.innerVisible = false
    },

    selectInit(row) {
      if (row.disabled) return false
      else return true
    },
    // 根据搜索信息查找邀请人名单
    async search() {
      if (this.searchParams.context === '') {
        return this.$message({
          showClose: true,
          message: this.$t('dc:请选择或者输入'),
          type: 'warning',
        })
      }
      let { status, data } = await $Api.groupAdmin.searchUserTab(this.searchParams)
      if (status === this.$RESPONSE_STATUS.SUCCESS && data.list.length !== 0) {
        this.showType = 1
        this.tableData = data.list
        this.searchParams.total = data.total
      } else {
        this.showType = 2
      }
    },
    submit() {
      if (this.showType == 2) {
        const mailReg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
        if (!mailReg.test(this.addEmail)) {
          this.$message({
            showClose: true,
            message: this.$t('dc:请输入正确的邮箱'),
            type: 'warning',
          })
          return (this.addEmail = '')
        } else {
          this.title = this.$t('dc:提醒')
          this.context = this.$t('dc:您确定要添加该用户吗?')
          this.innerVisible = true
        }
      } else if (this.showType == 1) {
        if (this.multipleSelection.length == 0 && this.addEmail == '') {
          return this.$message({
            showClose: true,
            message: this.$t('dc:请选择或者输入'),
            type: 'warning',
          })
        } else {
          this.title = this.$t('dc:提醒')
          this.context = this.$t('dc:您确定要添加该用户吗?')
          this.innerVisible = true
        }
      }
    },
    backSearch() {
      this.searchParams.context = ''
      this.multipleSelection = []
      this.addEmail = ''

      if (!this.showEmailStatus) this.showType = 0
      else this.showEmailStatus = false
    },
    select(selection, row) {
      this.$refs.multipleTable.clearSelection()
      if (selection.length == 0) return
      this.$refs.multipleTable.toggleRowSelection(row, true)
    },
    selectionChange(val) {
      this.multipleSelection = val
      // if(val.length > 1){
      //     this.$refs.multipleTable.clearSelection()
      //      this.$nextTick(() => {
      //       this.tableData.map((item,index) => {
      //         if (item.username == val.username)
      //           this.$refs.multipleTable.toggleRowSelection(val.pop(),false);
      //       });
      //   });

      //   }else{
      //     this.multipleSelection = val;
      //   }
    },
    rowClick(row, colum) {
      const selectData = this.multipleSelection
      this.$refs.multipleTable.clearSelection()
      if (selectData.length == 1) {
        selectData.forEach((item) => {
          if (item == row) {
            this.$refs.multipleTable.toggleRowSelection(row, false)
          } else this.$refs.multipleTable.toggleRowSelection(row, true)
        })
      } else this.$refs.multipleTable.toggleRowSelection(row, false)
    },
    queryApi() {
      this.innerVisible = false
      this.show = false
      this.$emit('close', true)
    },
    showEmail() {
      this.showEmailStatus = true
    },
    showAccount() {
      this.show4AddDialog = true
    },
    handleCurrentChange(v) {
      this.searchParams.pageNum = v
      this.search()
    },
    pageSizeChange(v) {
      this.pageConfig.pageNum = 1
      this.pageConfig.pageSize = v
      this.search()
    },
    pageChange(v) {
      this.searchParams.pageNum = v
      this.search()
    },
  },
}
</script>
<style lang="scss">
.addUser-dialog.home {
  .input-black {
    margin: 16px 0;
  }
  .searchMsg {
    .el-alert {
      padding: 0 16px 16px;
    }
    .el-alert.el-alert--error {
      padding: 0;
    }
    &.tabe-header-black {
      padding-bottom: 0;
    }
    .el-alert--success.is-light,
    .el-alert--error.is-light {
      background: transparent;
    }
    .el-alert__content {
      padding: 0;
    }
    .el-alert__icon {
      font-size: 34px;
      width: 34px;
      border-radius: 50%;
      overflow: hidden;
    }
    .el-alert.green .el-alert__icon {
      background: $--color-primary2;
      color: #fff;
    }
    .el-alert.red .el-alert__icon {
      background: rgb(229, 84, 65);
      margin-right: 10px;
      font-size: 24px;
      width: 24px;
      color: $--color-primary;
    }
    .el-alert__title {
      color: #fff;
    }
    .has-gutter .el-table-column--selection .cell {
      visibility: hidden;
    }
    .el-checkbox__inner {
      border-radius: 50%;
    }
    .email4account-group {
      padding: 10px 12px 0 34px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      a {
        cursor: pointer;
        color: #fff;
        text-decoration: underline;
        display: block;
      }
    }
    .el-input .el-input__inner {
      color: #fff;
    }
    // .el-input__inner:focus {
    //   border-color: #c4c8cf;
    // }
    .email-box {
      padding: 12px 0 0 0;
    }
    .input-email {
      margin: 8px 0;
    }
  }
  // .el-button {
  //   background: #000;
  //   border-color: #000;
  // }
  // .btn-green,
  // .btn-black {
  //   background: #48e30a;
  //   color: #000;
  // }
}
</style>
